// ./react-app/src/components/ListGrowthChart.tsx
import React, { useContext, useEffect, useState, useRef } from "react";
import { ResponsiveLine, Serie, DatumValue } from "@nivo/line";
import fetchData from "../../helpers/fetchData";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  formatDateToDM,
  formatGerman,
  parseDate,
} from "../../helpers/chartUtils";
import {
  getISOWeek,
  getMonth,
  groupBy,
} from "../../helpers/displayGroupingFuncs";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface IListSizeDailyResponse {
  date: string;
  subscribed: number;
  active: number;
  inactive: number;
  unsubscribed: number;
  cleaned: number;
}

const displayMapping = {
  subscribed: "Subscriber",
  active: "Aktive",
  inactive: "Inaktive",
  unsubscribed: "Abmelder",
  cleaned: "Gecleaned",
};

const getData: (
  filterState: IFilterState
) => Promise<IListSizeDailyResponse[]> = async (filterState: IFilterState) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  return (await fetchData<Record<string, any>[]>(
    "/api/mailchimp/list-size",
    options
  )) as IListSizeDailyResponse[];
};

const aggregateData = (
  data: IListSizeDailyResponse[],
  interval: "daily" | "weekly" | "monthly" | "yearly",
  selectedDataType: keyof IListSizeDailyResponse
): Record<string, any>[] => {
  switch (interval) {
    case "daily":
      return data.map((item) => ({
        date: item.date,
        value: item[selectedDataType],
      }));
    case "weekly":
    case "monthly":
      const groupedData = groupBy(data, (item) => {
        // For weekly or monthly grouping, you would need to convert the date
        // to a week number or a month string depending on the interval
        // For simplicity, let's assume we have a function that does that:
        return interval === "weekly"
          ? getISOWeek(item.date)
          : getMonth(item.date);
      });
      return Object.entries(groupedData).map(([date, items]) => ({
        date,
        value: Math.round(
          items.reduce(
            (sum, curr) => sum + (curr[selectedDataType] as number),
            0
          ) / items.length
        ),
      }));
    default:
      return [];
  }
};

interface CustomPoint {
  serieId: string | number;
  data: {
    yFormatted: number | string;
    xFormatted?: number | string;
  };
}

const CustomTooltip = ({ point }: { point: CustomPoint }) => {
  return (
    <div
      style={{
        padding: "12px",
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      {`${point.serieId}: ${formatGerman.format(
        point.data.yFormatted as number
      )}`}
    </div>
  );
};
const getTickValues = (data: Serie[], containerWidth: number): DatumValue[] => {
  if (!data[0]?.data) return [];

  const allValues = data[0].data.map(d => d.x).filter((x): x is DatumValue => x !== null && x !== undefined);
  const totalPoints = allValues.length;

  const estimatedLabelWidth = 45;
  const maxTicks = Math.floor(containerWidth / estimatedLabelWidth);

  if (totalPoints > maxTicks) {
    const interval = Math.ceil(totalPoints / maxTicks);
    return allValues.filter((_, index) => index % interval === 0);
  }

  return allValues;
};


const ListSizeLineChart: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<Serie[]>([]);
  const [selectedDataType, setSelectedDataType] = useState("subscribed");
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Add resize observer
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  // Add back the data fetching useEffect
  useEffect(() => {
    getData(filterState)
      .then((fetchedData) => {
        const transformedData = aggregateData(
          fetchedData,
          filterState.display || "daily",
          selectedDataType as keyof IListSizeDailyResponse
        );
        const lineData: Serie[] = [
          {
            id: displayMapping[selectedDataType as keyof typeof displayMapping],
            data: transformedData.map((item) => ({
              x: item.date,
              y: item.value,
            })),
            color: "#000000",
          },
        ];
        setData(lineData);
      })
      .catch((error: Error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState, selectedDataType]);

  const getFormattedTick = (value: string | number) => {
    switch (filterState.display) {
      case "weekly":
        return `KW ${String(value).split("-W")[1]}`;
      case "monthly":
        const [year, month] = String(value).split("-");
        return `${year}, ${month}`;
      case "daily":
      default:
        const date = parseDate(String(value));
        return date ? formatDateToDM(date) : "";
    }
  };

  // Get grid values ensuring they're all valid DatumValues
  const xGridValues = data[0]?.data
    ? getTickValues(data, containerWidth - 170)
    : undefined;

  // Calculate y-axis range with padding
  const yMin = data[0]?.data.reduce((min, point) => Math.min(min, point.y as number), Infinity) ?? 0;
  const yMax = data[0]?.data.reduce((max, point) => Math.max(max, point.y as number), -Infinity) ?? 100;
  const yAxisPadding = (yMax - yMin) * 0.1;

  return (
    <>
      <Box sx={{ position: "absolute", right: 20, marginTop: "-20px" }}>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Wert-Auswahl</InputLabel>
          <Select
            id="dataTypeSelect"
            value={selectedDataType}
            onChange={(e) => setSelectedDataType(e.target.value)}
          >
            {Object.keys(displayMapping).map((dataType) => (
              <MenuItem key={dataType} value={dataType}>
                {displayMapping[dataType as keyof typeof displayMapping]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <div ref={containerRef} style={{ width: '100%', height: '300px' }}>
        <ResponsiveLine
          data={data}
          tooltip={CustomTooltip}
          colors={{ datum: "color" }}
          margin={{ top: 20, right: 110, bottom: 60, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            stacked: false,
            min: Math.floor(yMin - yAxisPadding),
            max: Math.ceil(yMax + yAxisPadding),
          }}
          axisTop={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: -30,
            tickValues: getTickValues(data, containerWidth - 170),
            legend: "Datum",
            legendOffset: 45,
            legendPosition: "middle",
            format: getFormattedTick,
          }}
          axisLeft={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            format: (d) => formatGerman.format(d),
            tickValues: 8,
          }}
          enableGridX={true}
          gridXValues={xGridValues}
          enableGridY={true}
          gridYValues={8}
          lineWidth={3}
          enablePoints={false}
          layers={[
            "grid",
            "markers",
            "axes",
            "areas",
            "crosshair",
            "lines",
            "points",
            "slices",
            "mesh",
            "legends",
          ]}
          enableSlices={false}
          useMesh={true}
          crosshairType="cross"
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

export default ListSizeLineChart;
