// ./src/components/widgets/ResponsiveLineChart.tsx
import { getFormattedTick } from "../../helpers/chartUtils";
import { ResponsiveLine, Serie, DatumValue } from "@nivo/line";
import React from "react";
import { useChartTheme } from "../../themes/chartTheme";

interface ResponsiveLineChartProps {
  data: Serie[];
  display: string;
  customTooltip: any;
  containerWidth?: number;
}

const getTickValues = (data: Serie[], containerWidth: number): DatumValue[] => {
  if (!data[0]?.data) return [];

  const allValues = data[0].data.map(d => d.x).filter((x): x is DatumValue => x !== null && x !== undefined);
  const totalPoints = allValues.length;

  const estimatedLabelWidth = 45;
  const maxTicks = Math.floor(containerWidth / estimatedLabelWidth);

  if (totalPoints > maxTicks) {
    const interval = Math.ceil(totalPoints / maxTicks);
    return allValues.filter((_, index) => index % interval === 0);
  }

  return allValues;
};

const ResponsiveLineChart: React.FC<ResponsiveLineChartProps> = ({
  data,
  display,
  customTooltip,
  containerWidth,
}) => {
  const chartTheme = useChartTheme();

  const yMin = Math.min(
    ...data.map(serie =>
      Math.min(...serie.data.map(d => d.y as number))
    )
  );
  const yMax = Math.max(
    ...data.map(serie =>
      Math.max(...serie.data.map(d => d.y as number))
    )
  );
  const yAxisPadding = (yMax - yMin) * 0.1;

  // Get grid values ensuring they're all valid DatumValues
  const xGridValues = data[0]?.data && containerWidth
    ? getTickValues(data, containerWidth - 170)
    : undefined;

  // Default to showing all ticks if no containerWidth is provided
  const tickValues = containerWidth
    ? getTickValues(data, containerWidth - 170)
    : data[0]?.data.map(d => d.x).filter((x): x is DatumValue => x !== null && x !== undefined);

  return (
    <ResponsiveLine
      data={data}
      theme={chartTheme}
      tooltip={customTooltip}
      margin={{ top: 20, right: 110, bottom: 60, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        stacked: false,
        min: Math.floor(yMin - yAxisPadding),
        max: Math.ceil(yMax + yAxisPadding),
      }}
      axisTop={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 10,
        tickRotation: -30,
        tickValues: tickValues,
        legend: "Datum",
        legendOffset: 45,
        legendPosition: "middle",
        format: (value) => getFormattedTick(display, value),
      }}
      axisLeft={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 8,
      }}
      lineWidth={2}
      enableGridX={true}
      gridXValues={xGridValues}
      enableGridY={true}
      gridYValues={8}
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "points",
        "slices",
        "mesh",
        "legends",
      ]}
      pointSize={6}
      colors={{ datum: "color" }}
      pointColor={{ theme: "background" }}
      pointBorderWidth={1}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default ResponsiveLineChart;