import React, { useContext, useEffect, useState } from "react";
import { ResponsiveLine, Serie, DatumValue } from "@nivo/line";
import fetchData from "../../helpers/fetchData";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import { formatDateToDM, parseDate } from "../../helpers/chartUtils";
import {
  getISOWeek,
  getMonth,
  groupBy,
} from "../../helpers/displayGroupingFuncs";

interface IListGrowthDailyResponse {
  date: string;
  subscribed: number;
  unsubscribed: number;
}

const getData: (
  filterState: IFilterState
) => Promise<IListGrowthDailyResponse[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  return (await fetchData<Record<string, any>[]>(
    "/api/mailchimp/list-growth",
    options
  )) as IListGrowthDailyResponse[];
};

const aggregateData = (
  data: IListGrowthDailyResponse[],
  interval: "daily" | "weekly" | "monthly" | "yearly"
): Record<string, any>[] => {
  switch (interval) {
    case "daily":
      return data.map((item) => ({
        date: item.date,
        subscribed: item.subscribed,
        unsubscribed: item.unsubscribed,
      }));
    case "weekly":
    case "monthly":
      const groupedData = groupBy(data, (item) => {
        return interval === "weekly"
          ? getISOWeek(item.date)
          : getMonth(item.date);
      });
      return Object.entries(groupedData).map(([date, items]) => ({
        date,
        subscribed: items.reduce((sum, curr) => sum + curr.subscribed, 0),
        unsubscribed: items.reduce((sum, curr) => sum + curr.unsubscribed, 0),
      }));
    default:
      return [];
  }
};

interface CustomPoint {
  serieId: string | number;
  data: {
    yFormatted: number | string;
    xFormatted?: number | string;
  };
}

const CustomTooltip = ({ point }: { point: CustomPoint }) => {
  return (
    <div
      style={{
        padding: "12px",
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      {`${point.serieId}: ${point.data.yFormatted}`}
    </div>
  );
};

const getTickValues = (data: Serie[], containerWidth: number): DatumValue[] => {
  if (!data[0]?.data) return [];

  const allValues = data[0].data.map(d => d.x).filter((x): x is DatumValue => x !== null && x !== undefined);
  const totalPoints = allValues.length;

  const estimatedLabelWidth = 45;
  const maxTicks = Math.floor(containerWidth / estimatedLabelWidth);

  if (totalPoints > maxTicks) {
    const interval = Math.ceil(totalPoints / maxTicks);
    return allValues.filter((_, index) => index % interval === 0);
  }

  return allValues;
};

const NetListGrowthChart: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<Serie[]>([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    getData(filterState)
      .then((fetchedData) => {
        const transformedData = aggregateData(
          fetchedData,
          filterState.display || "daily"
        );
        const lineData: Serie[] = [
          {
            id: "Anmelder Entwicklung",
            data: transformedData.map((item) => ({
              x: item.date,
              y: item.subscribed - item.unsubscribed,
            })),
          },
        ];
        setData(lineData);
      })
      .catch((error: Error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);

  const getFormattedTick = (value: string | number) => {
    switch (filterState.display) {
      case "weekly":
        return `KW ${String(value).split("-W")[1]}`;
      case "monthly":
        const [year, month] = String(value).split("-");
        return `${year}, ${month}`;
      case "daily":
      default:
        const date = parseDate(String(value));
        return date ? formatDateToDM(date) : "";
    }
  };
// Calculate min and max for y-axis
  const yMin = data[0]?.data.reduce((min, point) => Math.min(min, point.y as number), Infinity) ?? 0;
  const yMax = data[0]?.data.reduce((max, point) => Math.max(max, point.y as number), -Infinity) ?? 100;

  // Add padding to y-axis scale
  const yAxisPadding = (yMax - yMin) * 0.1;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '300px' }}>
      <ResponsiveLine
        data={data}
        tooltip={CustomTooltip}
        margin={{ top: 20, right: 110, bottom: 60, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          stacked: false,
          min: Math.floor(Math.min(0, yMin - yAxisPadding)), // Ensure 0 is included and add padding
          max: Math.ceil(yMax + yAxisPadding), // Add padding to top
        }}
        axisTop={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: -30,
          tickValues: getTickValues(data, containerWidth - 170),
          legend: "Datum",
          legendOffset: 45,
          legendPosition: "middle",
          format: getFormattedTick,
        }}
        axisLeft={{
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 8, // Set approximate number of ticks
        }}
        lineWidth={3}
        enableGridX={true}
        gridXValues={getTickValues(data, containerWidth - 170)}
        enableGridY={true}
        gridYValues={8}
        enableArea={true}
        areaOpacity={0.3}
        areaBaselineValue={Math.floor(Math.min(0, yMin - yAxisPadding))} // Align area baseline with y-axis min
        enablePoints={false}
        useMesh={true}
        crosshairType="cross"
        colors="rgb(141, 160, 203)"
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default NetListGrowthChart;